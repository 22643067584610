$(function(){
    //#### placeholder START ####

    $('input[placeholder]').placeholder();

    //#### create/edit submit btn when click

    /*$('.loader-on-submit').click(function(){
     var t = $(this);
     t.html('').html('saving...');
     t.css({
     "opacity":"50%"
     });
     t.attr("disabled", true);
     this.form.submit();
     });

     //datapicker
     $('.datepicker').datepicker({
     format: "dd.mm.yyyy",
     language: "en",
     weekStart: 1
     });

     //timepicker
     $('.timepicker').timepicker({
     "defaultTime":"0:00",
     "showMeridian":false
     });

     //textarea wysihtml5
     $('.wysihtml5').wysihtml5({
     "font-styles": true, //Font styling, e.g. h1, h2, etc. Default true
     "emphasis": true, //Italics, bold, etc. Default true
     "lists": true, //(Un)ordered lists, e.g. Bullets, Numbers. Default true
     "html": false, //Button which allows you to edit the generated HTML. Default false
     "link": true, //Button to insert a link. Default true
     "image": false, //Button to insert an image. Default true,
     "color": false, //Button to change color of font,
     "locale": "bg-BG"
     });

     //popover
     $('.three_points_popover').popover({});

     //#### flowplayer

     $(".player").flowplayer({
     swf: "/flowplayer/flowplayer.swf"
     });

     //bootstrap switch
     $(".bootstrap-switch").bootstrapSwitch();

     //bootstrap select
     $('.selectpicker').selectpicker();

     $("[data-toggle='popover']").popover();*/

});



function closeClosestPopover(t)
{
    $(t).closest('div.popover').prev().popover('hide');
}

function sendMail()
{
    window.location = 'mailto:test@test.bg';
}