/**
 * Submit form from trigger element and watch for enter event in all inputs of the form.
 * @param form - form selectro
 * @param trigger - trigger element selector
 */
function formSubmit(form, trigger) {
    $(trigger).click(function () {
        $(form).submit();
    });
    /* submit on enter
     $(form + " "+ "input").keypress(function (e) {
     if ((e.which && e.which == 13) || (e.keyCode && e.keyCode == 13)) {
     $(form).submit();
     return false;
     } else {
     return true;
     }
     });
     */
}



/**
 * Return object with array form one JS array
 *the object can be used leter with - if( value in oc(JS_array) ) ...
 * @param a - array
 */
function oc(a) {
    var o = {};
    for (var i = 0; i < a.length; i++) {
        o[a[i].value] = '';
    }
    return o;
}

/**
 * Select/deselect any matching checkboxes, radio buttons or option elements.
 */
$.fn.selected = function (select) {
    if (select == undefined) select = true;
    return this.each(function () {
        var t = this.type;
        if (t == 'checkbox' || t == 'radio')
            this.checked = select;
        else if (this.tagName.toLowerCase() == 'option') {
            var $sel = $(this).parent('select');
            if (select && $sel[0] && $sel[0].type == 'select-one') {
                $sel.find('option').selected(false);
            }
            this.selected = select;
        }
    });
};


/** Add class to change the background color of checkbox in div
 *
 * @param objThis - this object
 * @param toggleClass - class to be added or removed
 */
function checkBoxChecked(objThis, toggleClass) {
    if ($(objThis).attr('checked')) {
        $(objThis).parent().addClass(toggleClass);
    } else {
        $(objThis).parent().removeClass(toggleClass);
    }
}


/** In use with input. Select only the default value of input.
 * How to use: onfocus="selectInputValueOnDefaultValue(this);"
 *
 * @param tthis - input obj (this)
 */
function selectInputValueOnDefaultValue(tthis) {
    if (tthis.value == tthis.defaultValue) {
        tthis.select();
    }

}

// #### PHP uniqueid() equivalent for javascript START ####

function uniqid(prefix, more_entropy) {
    if (typeof prefix == 'undefined') {
        prefix = "";
    }
    var retId;
    var formatSeed = function (seed, reqWidth) {
        seed = parseInt(seed, 10).toString(16); // to hex str
        if (reqWidth < seed.length) { // so long we split
            return seed.slice(seed.length - reqWidth);
        }
        if (reqWidth > seed.length) { // so short we pad
            return Array(1 + (reqWidth - seed.length)).join('0') + seed;
        }
        return seed;
    };

    // BEGIN REDUNDANT
    if (!this.php_js) {
        this.php_js = {};
    }
    // END REDUNDANT
    if (!this.php_js.uniqidSeed) { // init seed with big random int
        this.php_js.uniqidSeed = Math.floor(Math.random() * 0x75bcd15);
    }
    this.php_js.uniqidSeed++;

    retId = prefix; // start with prefix, add current milliseconds hex string
    retId += formatSeed(parseInt(new Date().getTime() / 1000, 10), 8);
    retId += formatSeed(this.php_js.uniqidSeed, 5); // add seed hex string
    if (more_entropy) {
        // for more entropy we add a float lower to 10
        retId += (Math.random() * 10).toFixed(8).toString();
    }

    return retId;
}


// #### PHP uniqueid() equivalent for javascript END ####



/**
 * Use  $('div').classes(); return: ["amet", "sit", "dolor_spec", "ipsum", "Lorem"]
 */
(function ($) {
    $.fn.classes = function (f) {
        var c = [];
        $.each(this, function (i, v) {
            var _ = v.className.split(/\s+/);
            for (var j in _)
                '' === _[j] || c.push(_[j]);
        });
        c = $.unique(c);
        if ("function" === typeof f)
            for (var j in c)
                f(c[j]);
        return c;
    };
})(jQuery);

/**
 * Check if browser is mobil or not
 * @returns bool
 */
function is_browser_mobil() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent);
}

/**
 * Return the size of object used as array
 * @param obj
 * @returns {number}
 */
function object_size(obj) {
    var size = 0, key;
    for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
    }
    return size;
}

function precise_round(num, decimals) {
    return Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

/**
 *  Just uncheck all other checkboxes by click on one of them.
 * @param t - this, clicked checkbox
 * @param sel - sel class for all checkboxes
 */
function uncheckAllOthers(t, sel) {
    $(sel).each(function () {
        if (t != this) {
            $(this).removeAttr('checked');
        }
    });
}

/*
 Round Half To Even (rhte) extends the 'Number' and 'String' objects
 Argument(placeToRound) examples: 0.0001,0.01,1,10,100
 Usage:
 Number.rhte(rounding value)
 (124.34450000).rhte(0.001) - [Result: 124.344]
 (124.34450001).rhte(0.001) - [Result: 124.345]
 (124.34550000).rhte(0.001) - [Result: 124.346]
 String.rhte(rounding value)
 '124.34450000'.rhte(0.001) - [Result: 124.344]
 '124.34450001'.rhte(0.001) - [Result: 124.345]
 '124.34550000'.rhte(0.001) - [Result: 124.346]
 */
var rhte = function(placeToRound){
    var fixed = placeToRound.toString().split('.').length < 2 ? 0 : placeToRound.toString().split('.')[1].length,
        numParts = {
            mvDec : (this/placeToRound).toFixed(this.toString().length).toString().split('.'),
            wholeNum: function(){return parseInt(this.mvDec[0],10);},
            dec: function(){return this.mvDec.length > 1 ? parseFloat('0.'+this.mvDec[1]) : 0;},
            oddEven: function(){return (this.wholeNum() % 2 === 1) ? 1 : 0;}
        };

    if(numParts.dec() !== 0.5){
        return(numParts.dec() > 0.5) ? parseFloat(((numParts.wholeNum()+1)*placeToRound).toFixed(fixed)) : parseFloat((numParts.wholeNum()*placeToRound).toFixed(fixed));
    }
    else{
        if(numParts.oddEven() === 1){
            return parseFloat(((numParts.wholeNum()+1)*placeToRound).toFixed(fixed));
        }
        else{
            return parseFloat((numParts.wholeNum()*placeToRound).toFixed(fixed));
        }
    }
};
Number.prototype.rhte = rhte;
String.prototype.rhte = rhte;