
/*
 * ADMIN APP
 * Autor: Lachezar Grigorov
 * version: 0.0.5
 */

(function (root, factory) {

    "use strict";
    if (typeof define === "function" && define.amd) {
        // AMD. Register as an anonymous module.
        define(["jquery"], factory);
    } else if (typeof exports === "object") {
        // Node. Does not work with strict CommonJS, but
        // only CommonJS-like environments that support module.exports,
        // like Node.
        module.exports = factory(require("jquery"));
    } else {
        // Browser globals (root is window)
        root.admin = factory(root.jQuery);
    }
}(this, function($, undefined) {

    var DOMElements = {
        loader: $('#loader-main')
    };

    //public object
    var exports = {};

    exports.successBtnsByFormAction = [];

    exports.showMainLoaderVar = true;
    exports.requestCount = 0;

    exports.showMainLoader = function()
    {
        exports.requestCount++;
        setTimeout(function()
        {
            if(exports.showMainLoaderVar && exports.requestCount > 0)
            {
                DOMElements.loader.removeClass("hidden");
            }

        },400);

    };

    exports.hideMainLoader = function()
    {
        exports.requestCount--;
        exports.showMainLoaderVar = false;
        if(exports.requestCount <= 0 && !DOMElements.loader.hasClass("hidden")) {
            DOMElements.loader.addClass("hidden");
        }
    };

    exports.addAlertMsg = function (msg,container)
    {
        var $container = container;
        $container.empty();
        switch(msg.type)
        {
            case "success":
                $('<div class="alert alert-success fade in" ><button class="close" data-dismiss="alert" >×</button ><i class="fa-fw fa fa-check" ></i > '+msg.msg+'</div >').appendTo($container);
                break;
            case "info":
                $('<div class="alert alert-info fade in" ><button class="close" data-dismiss="alert" >×</button ><i class="fa-fw fa fa-info" ></i > '+msg.msg+'</div >').appendTo($container);
                break;
            case "warning":
                $('<div class="alert alert-warning fade in" ><button class="close" data-dismiss="alert" >×</button ><i class="fa-fw fa fa-warning" ></i > '+msg.msg+'</div >').appendTo($container);
                break;
            case "danger":
                $('<div class="alert alert-danger fade in" ><button class="close" data-dismiss="alert" >×</button ><i class="fa-fw fa fa-times" ></i > '+msg.msg+'</div >').appendTo($container);
                break;
        }
    };

    exports.addProgressBar = function(container)
    {
        var $progressBar = $('<div>' +
        '<h5 class="text-align-center" style="margin-bottom: 2px;"><small>'+_TRANS.loading+'</small></h5>' +
        '<div class="progress">' +
        '<div class="progress-bar bg-color-blue"  style="width: 0%;">0%</div>' +
        '</div>' +
        '</div>');
        $progressBar.appendTo(container);
        return $progressBar;
    }


    exports.showIndexModal = function(url,selDatatable)
    {
        //just to prevent some bug hide here the main loader
        DOMElements.loader.addClass("hidden");

        selDatatable = (selDatatable != undefined) ?  selDatatable : "#datatable";
        var oTable = datatable.objects[selDatatable.substring(1)];
        $.ajax({
            url:url,
            beforeSend:function(){
                exports.showMainLoader();
            },
            success:function(data){
                exports.hideMainLoader();

                if(data.redirectTo)
                {
                    window.location = data.redirectTo;
                }
                else
                {
                    bootbox.dialog({
                        message  : data.message,
                        title    : data.title,
                        className: "modal_dialog_fullscreen",
                        buttons  : {
                            cancel: {
                                label    : _TRANS.hide,
                                className: "btn btn-danger",
                                callback : function ()
                                {
                                    if (oTable)
                                        oTable.fnStandingRedraw();
                                }
                            }
                        }

                    })
                }
            }
        });
    }

    /**
     *
     * @param url
     * @param formSelector
     * @param successBtnLabel - string as btn label or object {modal:"btn label", content:"btn label"} [btn-success] in use and must have different label
     * @param selDatatable
     * @param selAlertsContainer
     * @param selProgressBarContainer
     * @param promptByCancel - if set as some message "Are you sure, you want to close this dialog!"
     *                         then on cancel click the code will prompt the user if really want to close the modal
     * @param bSmallDialog - if true the dialog will be small
     *
     * MODAL REMAIN ACTIVE OPTION
     * 1. Modal stay active when the data-modal-remain-active-on-submit=true is set in form element
     *
     * ATTENSION: If you use it make sure to set the proper ids for: selDatatable, selAlertsContainer, selProgressBarContainer
     *
     * 2. every content succes btn didn't hide the modal
     * Example: Set [btn-succes] everywhere in content of the modal.
     *
     * ATTENSION: If you use it make sure to set the selAlertsContainer+"-modal" and selProgressBarContainer+"-modal" elements
     * inside the content of the bootbox so the alert message and progressbar to appear in content if [btn-succes] btn clicked.
     * Make sure to set the selAlertsContainer (without "-modal") and selProgressBarContainer (without "-modal") elements into
     * the mask under the bootbox so the alert message and progressbar to appear after bootbox is closed from the base bootbox success btn.
     *

     Controller can return

     1.msgs

     "msgs"=>array(
     array(
     "type" => "success",
     "msg"  => trans("message.project_create")
     )
     )

     2. redirectTo

     "redirectTo"=>action('Admin\ContactsController@getIndex')

     3.eval

     "eval"=> $this->repoProjects->getShowCreateEditModelJSCode($model->id)

     4.executeFunction

     "executeFunction"=>array(
     "name"=>"functionToExecute",
     "data"=>[
     "id"=>$model->id,
     "label" => $label
     ]
     )
     */
    exports.showCreateEditModal = function(url,formSelector,successBtnLabel,selDatatable,selAlertsContainer,selProgressBarContainer,promptByCancel,bSmallDialog)
    {
        //just to prevent some bug hide here the main loader
        DOMElements.loader.addClass("hidden");

        selAlertsContainer = (selAlertsContainer != undefined) ?  selAlertsContainer : "#alerts-container";
        selProgressBarContainer = (selProgressBarContainer != undefined) ?  selProgressBarContainer : "#progressbar-container";
        selDatatable = (selDatatable != undefined) ?  selDatatable : "#datatable";
        bSmallDialog = (bSmallDialog != undefined) ?  "" : "modal_dialog_fullscreen";

        var oTable = datatable.objects[selDatatable.substring(1)],
            editBtnUniqueClass = "",
            editAccessMsg = "",
            successBtnsUniqueClass = "bootbox-success-btns-unique-class_"+uniqid();


        if(_CONFIG.edit_access.do)
        {
            var editAccessMsgUniqueId = "edit-access-msg-container_"+uniqid();
            editBtnUniqueClass = "edit-access-btn-edit_"+uniqid();
            editAccessMsg = "<div id='"+editAccessMsgUniqueId+"'></div>";
        }

        $.ajax({
            url:url,
            beforeSend:function(){
                exports.showMainLoader();
            },
            success:function(data,status,xhr){

                exports.hideMainLoader();

                var box = bootbox.dialog({
                    message: editAccessMsg+data.message,
                    title: data.title,
                    className: bSmallDialog,
                    buttons: {
                        success: {
                            label: successBtnLabel,
                            className: successBtnsUniqueClass+" btn btn-success "+editBtnUniqueClass,
                            callback: function(event) {

                                exports.successBtnsByFormAction.push({formAction:$('#'+formSelector).attr('action'),"class":successBtnsUniqueClass});

                                //when bootstrapValidator fail then the showCreateEditModalSubmitForm return 'bootstrapValidatorFail' so just return false
                                var returned = showCreateEditModalSubmitForm(event,formSelector,oTable,selAlertsContainer,selProgressBarContainer);
                                if(returned == 'bootstrapValidatorFail') return false;
                                else return returned;
                            }
                        },
                        cancel: {
                            label: _TRANS.close,
                            className: "btn btn-danger",
                            callback: function() {
                                if(promptByCancel)
                                {
                                    bootbox.dialog({
                                        message: promptByCancel,
                                        title: _TRANS.attension,
                                        buttons: {
                                            danger: {
                                                label: _TRANS.no_times,
                                                className: "btn-danger",
                                                callback: function() {
                                                }
                                            },
                                            success: {
                                                label: _TRANS.yes_check,
                                                className: "btn-success",
                                                callback: function() {
                                                    box.modal('hide');
                                                    //refresh table on modal hide
                                                    if(oTable)
                                                        oTable.fnStandingRedraw();
                                                }
                                            }
                                        }
                                    });
                                    return false;
                                }
                                else
                                {
                                    //refresh table on modal hide
                                    if(oTable)
                                        oTable.fnStandingRedraw();
                                }

                            }
                        }
                    }

                });

                box.on("shown.bs.modal", function() {
                    var canBeEdited = true;
                    var $form = $('#' + formSelector);
                    $form.find("input[type=text]:first").focus();
                    $form.find("textarea:visible").css("resizable","none").resizable({
                        minHeight: 50,
                        minWidth: 100
                    });
                    //echeck editing access if needed
                    if(_CONFIG.edit_access.do)
                    {
                        var modelName = $form.find('input[name=e_a_m_n]').val(),
                            modelId = $form.find('input[name=e_a_m_i]').val();
                        $.post(_CONFIG.edit_access.url.check, {
                            "e_a_m_n": modelName,
                            "e_a_m_i": modelId
                        }, function (data)
                        {
                            if (! data.access)
                            {
                                $('.' + editBtnUniqueClass).remove();
                                if (data.msg)
                                    $('#' + editAccessMsgUniqueId).html(data.msg);
                                canBeEdited = false;
                            }
                        });

                        var can_edit = $('#' + formSelector).find('input[name=can_edit]').val() != 0;

                        if(!can_edit) {
                            $('.' + editBtnUniqueClass).hide();
                            canBeEdited = false;
                        }
                    }

                    //fix the problem when key "enter" (13) entered and the form submit itself not through ajax
                    $form.on("keyup keypress",function(e) {
                        //prevent entering enter when can't be edited

                        if ($(e.target).is(':not(textarea)'))
                        {
                            var code = e.keyCode || e.which;
                            if (code == 13)
                            {
                                if(canBeEdited)
                                {
                                    if (e.type == "keyup")
                                    {
                                        e.preventDefault();
                                        var returned = showCreateEditModalSubmitForm(undefined, formSelector, oTable, selAlertsContainer, selProgressBarContainer);
                                        //do not close the modal when the bootstrapValidator fail and the showCreateEditModalSubmitForm return 'bootstrapValidatorFail'
                                        if (returned != 'bootstrapValidatorFail')
                                        {
                                            box.modal('hide');
                                        }
                                        return false;
                                    }
                                    else if (e.type == "keypress")
                                    {
                                        e.preventDefault();
                                        return false;
                                    }
                                }else
                                {
                                    e.preventDefault();
                                    return false;
                                }
                            }
                        }

                    });
                });

                box.on("hide.bs.modal", function() {
                    //delete edit_acces record
                    if(_CONFIG.edit_access.do)
                    {
                        var $form = $('#'+formSelector),
                            modelName = $form.find('input[name=e_a_m_n]').val(),
                            modelId = $form.find('input[name=e_a_m_i]').val();
                        $.post(_CONFIG.edit_access.url.clear,{"e_a_m_n":modelName,"e_a_m_i":modelId},function(data){});
                    }
                });
            }
        });
    }

    function showCreateEditModalSubmitForm(event,formSelector,oTable,selAlertsContainer,selProgressBarContainer)
    {
        var $form = $('#'+formSelector),
            formData = new FormData($form[0]),
            percents = 0,
            $progressBar = false,
            refreshTable = true,
            bootstrapValidator = $form.data('bootstrapValidator'),
            triggeredFromContentBtn = false,
            $selAlertsContainer = $(selAlertsContainer),
            $selProgressBarContainer = $(selProgressBarContainer);

        if(event && $(event.target).data("modal-remain-active-on-submit"))
        {

            triggeredFromContentBtn = true;
            $selAlertsContainer = $(selAlertsContainer+"-modal");
            $selProgressBarContainer = $(selProgressBarContainer+"-modal");
        }

        if(bootstrapValidator)
        {
            bootstrapValidator.validate();
            if (! bootstrapValidator.isValid())
            {
                return "bootstrapValidatorFail";
            }
        }
        $.ajax({
            url: $form.attr('action'),
            type: 'POST',
            data: formData,
            success: function (data) {
                $progressBar.remove();
                if(data.redirectTo)
                {
                    refreshTable = false;
                    window.location = data.redirectTo;
                }
                else
                {
                    if(data.msgs)
                    {
                        for (msg in data.msgs)
                        {
                            if(data.msgs[msg].type == "danger") refreshTable = false;
                            exports.addAlertMsg(data.msgs[msg],$selAlertsContainer);
                        }

                        if(triggeredFromContentBtn) {
                            var ajaxTab = $(event.target).closest('.bootbox-body').find('.ajax-tabs');
                            if (ajaxTab.length > 0) {
                                ajaxTab.each(function () {
                                    $(this).tabs('load', $(this).tabs('option', 'active'));
                                });
                            }
                        }
                    }
                    if(data.executeFunction)
                    {

                        if( data.executeFunction instanceof Array)
                        {
                            for(position in data.executeFunction)
                            {
                                var ef = data.executeFunction[position];
                                executeFunctionByName(ef.name,window,ef.data);
                            }
                        }
                        else
                        {
                            executeFunctionByName(data.executeFunction.name,window,data.executeFunction.data);
                        }

                    }

                    if(data.eval)
                    {
                        eval(data.eval);
                    }
                    if(refreshTable && oTable)
                        oTable.fnStandingRedraw();
                }
            },
            cache: false,
            contentType: false,
            processData: false,
            error:function(data)
            {
                $progressBar.remove();
            },
            xhr: function() {  // Custom XMLHttpRequest
                var myXhr = $.ajaxSettings.xhr();
                if(myXhr.upload){ // Check if upload property exists
                    myXhr.upload.addEventListener('progress',function(e)
                    {
                        if(e.lengthComputable)
                        {
                            if(!$progressBar) $progressBar = exports.addProgressBar($selProgressBarContainer);
                            percents = Math.round((e.loaded / e.total ) *  100);
                            $progressBar.find('.progress-bar').css("width",percents+"%").text(percents+"%");
                        }
                    }, false); // For handling the progress of the upload
                }
                return myXhr;
            }
        });
        if(triggeredFromContentBtn) return false;
        //if data-modal-remain-on-submit=true set in form element then return false to stop dismissing of the bootbox
        if($form.data("modal-remain-active-on-submit")) return false;

        return true;
    }

    /**
     *
     * @param url
     * @param id
     * @param msg
     * @paramselDatatabler
     * @paramselAlertsContainerr
     */
    exports.showDeleteModal = function(url,id,msg,selDatatable,selAlertsContainer)
    {
        selAlertsContainer = (selAlertsContainer != undefined) ?  $(selAlertsContainer) : $("#alerts-container");
        msg = (msg != undefined) ?  msg : _TRANS.are_you_sure;
        selDatatable = (selDatatable != undefined) ?  selDatatable : "#datatable";

        var oTable = datatable.objects[selDatatable.substring(1)],
            ajaxData = (typeof id == 'object') ? id : {id:id};
        bootbox.dialog({
            message: msg,
            title: _TRANS.attension,
            buttons: {
                danger: {
                    label: _TRANS.no_undo,
                    className: "btn-danger",
                    callback: function() {
                    }
                },
                success: {
                    label: _TRANS.yes_trash,
                    className: "btn-success",
                    callback: function() {
                        var  refreshTable = true;
                        $.ajax({
                            url: url,
                            type: 'POST',
                            data: ajaxData,
                            success: function (data) {
                                if(data.redirectTo)
                                {
                                    refreshTable = false;
                                    window.location = data.redirectTo;
                                }
                                else
                                {
                                    if(data.msgs)
                                    {
                                        for (msg in data.msgs)
                                        {
                                            if(data.msgs[msg].type == "danger") refreshTable = false;
                                            exports.addAlertMsg(data.msgs[msg],selAlertsContainer);
                                        }
                                    }
                                    if(data.executeFunction)
                                    {
                                        if( data.executeFunction instanceof Array)
                                        {
                                            for(position in data.executeFunction)
                                            {
                                                var ef = data.executeFunction[position];
                                                executeFunctionByName(ef.name,window,ef.data);
                                            }
                                        }
                                        else
                                        {
                                            executeFunctionByName(data.executeFunction.name,window,data.executeFunction.data);
                                        }

                                    }
                                    if(refreshTable && oTable)
                                        oTable.fnStandingRedraw();
                                }
                            },
                            cache: false,
                            error:function(data)
                            {
                            }
                        });
                    }
                }
            }
        });
    };

    /**
     *
     * @param url - post url to call the controller
     * @param data - data to send to the controller
     * @param modalToClose - modal object to close;  most used - $(this).closest(".bootbox")
     * @param selAlertsContainer - alert container selector, if msgs needed
     * @param prompt - if set then bootbox prompt before action
     *
     Controller must return msgs, redirectTo or executeFunction directive in form ->

     "executeFunction"=>array(
     "name"=>"functionToExecute",
     "data"=>[
     "id"=>$model->id,
     "label" => $label
     ]
     )
     */

    exports.controllerTalk = function(url,data,modalToClose,selAlertsContainer,prompt)
    {
        selAlertsContainer = (selAlertsContainer != undefined) ?  $(selAlertsContainer) : $("#alerts-container");

        if(prompt)
        {
            bootbox.dialog({
                message: prompt,
                title: _TRANS.attension,
                buttons: {
                    danger: {
                        label: _TRANS.no_times,
                        className: "btn-danger",
                        callback: function() {
                        }
                    },
                    success: {
                        label: _TRANS.yes_check,
                        className: "btn-success",
                        callback: function() {
                            controllerTalkAjaxCall(url,data,modalToClose,selAlertsContainer);
                        }
                    }
                }
            });
        }
        else
        {
            controllerTalkAjaxCall(url,data,modalToClose,selAlertsContainer);
        }
    };

    function controllerTalkAjaxCall(url,data,modalToClose,selAlertsContainer)
    {
        $.ajax({
            url:url,
            method:"POST",
            data:data,
            beforeSend:function(){
                exports.showMainLoader();

            },
            success:function(data){
                exports.hideMainLoader();
                if(data.redirectTo)
                {
                    window.location = data.redirectTo;
                }
                else
                {
                    if(data.msgs)
                    {
                        for (msg in data.msgs)
                        {
                            exports.addAlertMsg(data.msgs[msg],selAlertsContainer);
                        }
                    }
                    if(data.executeFunction)
                    {

                        if( data.executeFunction instanceof Array)
                        {
                            for(position in data.executeFunction)
                            {
                                var ef = data.executeFunction[position];
                                executeFunctionByName(ef.name,window,ef.data);
                            }
                        }
                        else
                        {
                            executeFunctionByName(data.executeFunction.name,window,data.executeFunction.data);
                        }

                        if(modalToClose) modalToClose.modal("hide");
                    }
                }
            }
        });
    }

    function executeFunctionByName(functionName, context /*, args */) {
        var args = [].slice.call(arguments).splice(2);
        var namespaces = functionName.split(".");
        var func = namespaces.pop();
        for(var i = 0; i < namespaces.length; i++) {
            context = context[namespaces[i]];
        }
        if(typeof context[func] == 'function') {
            return context[func].apply(this, args);
        } else {
            console.log("Missing function: " + func);
        }
    }

    /**
     * Change attached label for given data.sel and data.html.
     * In use with controllerTalk and executeFunction send from the controller.
     * @param data
     */
    exports.changeAttachedLabel = function(data)
    {
        $(data.sel).html(data.html);
    };

    return exports;
}));

$(function(){
    $( document ).ajaxSuccess(function( event, request, settings ) {

        //change success btns label to "edit" when form success
        if(request.responseText.indexOf('"type":"success"') > -1)
        {
            //admin.successBtnsByFormAction contains an array with formAction and success btns unique class
            for(var index in admin.successBtnsByFormAction)
            {
                //find current active success btns on screen and change their label
                if(settings.url == admin.successBtnsByFormAction[index].formAction)
                {
                    var btnsUniqueClass = admin.successBtnsByFormAction[index].class;
                    $('.'+btnsUniqueClass).each(function(e)
                    {
                        var $btn = $(this);
                        $btn.html($btn.html().replace(/Erstellen/i,"Speichern"));
                    });
                    break;
                }
            }
        }
        setTimeout(function ()
        {
            admin.showMainLoaderVar = true;
        }, 300);

    });

    //check on every ajax call if user still logged in or redirect to logout page
    $( document ).ajaxError(function( event, request, settings ) {
        if(request.status == 401)
        {
            window.location = _URL+"/admin/auth/logout"
        }
        else
        {
            admin.hideMainLoader();
            $('.loader-main-error').removeClass("hidden");
        }
    });
});
