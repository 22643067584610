

/*
 * DATATABLE APP
 * Autor: Lachezar Grigorov
 */

(function (root, factory) {

    "use strict";
    if (typeof define === "function" && define.amd) {
        // AMD. Register as an anonymous module.
        define(["jquery"], factory);
    } else if (typeof exports === "object") {
        // Node. Does not work with strict CommonJS, but
        // only CommonJS-like environments that support module.exports,
        // like Node.
        module.exports = factory(require("jquery"));
    } else {
        // Browser globals (root is window)
        root.datatable = factory(root.jQuery);
    }
}(this, function($, undefined) {

    var DOMElements = {
    };

    //public object
    var exports = {};

    exports.objects = {

    };

    /** Init datatable
     *
     * @param options
     * @param classes
     * @param columnFilters
     * @param selector
     */
    exports.init = function(options,classes,columnFilters,selector)
    {
        selector = (selector != undefined) ?  selector : "#datatable";

        var table = $(selector),
            filters = $('.datatable-filters', table),
            oTable;

        if(typeof classes != undefined)
        {
            // Used when bJQueryUI is false
            $.extend($.fn.dataTableExt.oStdClasses, classes);
            // Used when bJQueryUI is true
            $.extend($.fn.dataTableExt.oJUIClasses, classes);
        }

        var port = "";
        if(window.location.port == 8000) port = ":8000";
        var defaults = {
            //bStateSave: true,
            bAutoWidth: false,
            bProcessing: true,
            bDeferRender: true,
            "aLengthMenu": [[20, 50, 100, -1], [20, 50, 100, _TRANS.all]],
            "oLanguage": {
                "sUrl": 'https://'+window.location.hostname+port+'/vendor/datatables/locales/' + _LANG + '.txt'

            },
            "dom": "flrtpi",
            "fnInitComplete": function(oSettings, json) {
                $(selector+'_filter input').attr("placeholder",_TRANS.datatable_global_search);
                $(selector+'_filter input').addClass("input-xs");
                $(selector+'_length select').addClass("input-xs");
            }

        };

        if(typeof options != undefined)
        {
            $.extend(defaults,options)
        }

        if(typeof columnFilters != undefined)
        {
            oTable = table.dataTable(defaults).columnFilter(columnFilters);
        }else
        {
            oTable = table.dataTable(defaults);
        }


        filters.on('keyup', 'input[type=text]', function (e) {
            oTable.fnFilter(this.value, $(this).parent().index());
        });
        filters.on('change', '.datepicker', function (e) {
            oTable.fnFilter(this.value, $(this).parent().index());
        });

        $('.datatable-filters .datatable-select', table).each( function ( i ) {
            i = $(this).index();
            this.innerHTML = fnCreateSelect( oTable.fnGetColumnData(i) );
            $('select', this).change( function () {

                oTable.fnFilter( $(this).val(), i );
            } );
        } );
        exports.objects[selector.substring(1)] = oTable;
        initResponsiveTableDropdownFix();
    };

    /*function filterByUrlColumnsOnInit()
     {
     var vars = {},
     hash,
     max = -1,
     columns = [];
     var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
     for(var i = 0; i < hashes.length; i++)
     {
     hash = hashes[i].split('=');
     if(hash[0].substring(0,3) == "col")
     {

     var hashKeys = hash[0].split("_");
     if(hashKeys[1] > max) max = hashKeys[1];
     vars[hash[0]] = decodeURIComponent(hash[1]);

     }
     }
     if(max > -1)
     {
     for(var c = 0; c <= max; c++)
     {
     if(vars["col_"+c]) columns.push({"sSearch": vars["col_"+c]});
     else columns.push(null);
     }
     }

     return {"aoSearchCols":columns};

     }*/

    function fnCreateSelect( aData )
    {
        var r='<select class="form-control"><option value=""></option>', i, iLen=aData.length;
        for ( i=0 ; i<iLen ; i++ )
        {
            var label = aData[i];
            label = $.trim(label.replace(/<\/?[^>]+(>|$)/g, ""));

            r += '<option value="'+label+'">'+label+'</option>';
        }
        return r+'</select>';
    }

    /**
     * Export datatable content
     * @param url
     * @param selDataTable
     * @param extraData
     */

    exports.export = function(url,selDatatable,extraData)
    {
        var params = exports.getAjaxParams(selDatatable,extraData);
        for(var i = 0; i <= 50; i++)
        {
            delete params["mDataProp_"+i];
            delete params["bRegex_"+i];
        }
        console.log($.param(params));
        window.location = url+"?"+ $.param(params);
    };


    /**
     * Return the last datatable ajax params for datatable id.
     * Extra data can be set.
     * @param selDatatable - datatable selector
     * @param extraData - extra data
     * @returns {*}
     */
    exports.getAjaxParams = function(selDatatable,extraData)
    {
        var oTable = exports.objects[selDatatable.substring(1)];
        var params = $.extend(oTable.api().ajax.params(),extraData);

        //fix the bug where ajax.params() return empty sSearch_... for range filters
        for(var i = 0; i< params.iColumns; i++)
        {
            var $from = $(selDatatable+"_range_from_"+i),
                $to = $(selDatatable+"_range_to_"+i);
            if($from.length && $to.length)
            {
                var from = $.trim($from.val()),
                    to = $.trim($to.val());
                params["sSearch_"+i] = from+"~"+to;
            }
        }

        return params;
    };

    /**
     *  Toggle checkboxes
     * @param t - this checkbox element, the checked state of this element will be set to all others
     * @param checkboxesClass - checkboxes class
     * @param datatableId - datatable id, not required, if set, the script will select only the checkboxes in this datatable
     */
    exports.toggleCheckboxes = function(t,checkboxesClass,datatableId)
    {
        var $t = $(t);
        if(datatableId != undefined)
        {
            var $checkboxes = $(datatableId+" input."+checkboxesClass);
        }
        else
        {
            var $checkboxes = $("input."+checkboxesClass);
        }
        $checkboxes.prop("checked",$t.prop("checked")).change();
    };


    /**
     * Refresh datatable by id
     * @param data - datatableID without # at the beginning
     */

    exports.refresh = function(data)
    {
        exports.objects[data.datatableID.substring(1)].fnStandingRedraw();
    };

    return exports;
}));