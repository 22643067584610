

$(function () {

    initAjaxTabs();
    initModalSelects();

    //Dropdown fix ot table-responsive
    $('.table-responsive').on('show.bs.dropdown', function (event) {
        if($(event.relatedTarget).closest('tr').is(':last-child')) {
            var $dropdownH = $(event.relatedTarget).next().height();
            if($dropdownH > 30) {
                $(this).css( "min-height", ($(this).height() + $dropdownH - 25) + "px" );
            }
        }
    });
    $('.table-responsive').on('hide.bs.dropdown', function (event) {
        if($(event.relatedTarget).closest('tr').is(':last-child')) {
            if($(this).css( "min-height") != "0px") {
                $(this).css( "min-height", "0px" );
            }
        }
    });
});

function initModalSelects()
{
    window.updateModalSelect = function (container, value, label)
    {
        var container = $('.select-modal#'+container);
        container.find('.select-value').val(value);
        container.find('.select-label').html(label);
        container.find('.select-detach').show();
    }

    $('.select-detach').click(function() {
        $(this).closest('.select-modal').find('.select-value').val(0);
        $(this).closest('.select-modal').find('.select-label').html('');
        $(this).hide();
    });
}

function initAjaxTabs()
{
    $( ".ajax-tabs" ).tabs({
        beforeLoad: function( event, ui ) {
            ui.panel.html('<div style="height: 200px; position:relative;"><div id="loader-main" class="loading-wrapper loading-wrapper-white" style="min-height: 200px;"><div class="loading">Laden</div></div></div>');
            ui.jqXHR.fail(function() {
                ui.panel.html( "Dieses Register konnte nicht geladen werden. Wir werden versuchen, diese so schnell wie möglich zu beheben. " );
            });
        }
    });
}

/*
 Must use parameters for data "container, label, id"
 */
function changeLabelsOnEditedItem(data) {
    var editLabelContainer = $('.'+data.container+'[data-id='+data.id+']');
    if(editLabelContainer.length) {
        editLabelContainer.each(function(index, value) {
            if($(this).has('a').length) {
                $(this).children('a').html('<i class="fa fa-pencil"> '+data.label);
            } else {
                $(this).html(data.label);
            }
        });
    }
}

function initResponsiveTableDropdownFix() {

    //Dropdown fix ot table-responsive
    $('.table-responsive').off('show.bs.dropdown').on('show.bs.dropdown', function (event) {
        if($(event.relatedTarget).closest('tr').is(':last-child')) {
            var $dropdownH = $(event.relatedTarget).next().height();
            if($dropdownH > 30) {
                $(this).css( "min-height", ($(this).height() + $dropdownH - 25) + "px" );
            }
        }
    });
    $('.table-responsive').off('hide.bs.dropdown').on('hide.bs.dropdown', function (event) {
        if($(event.relatedTarget).closest('tr').is(':last-child')) {
            if($(this).css( "min-height") != "0px") {
                $(this).css( "min-height", "0px" );
            }
        }
    });
}

function updateDocumentPayerFirmIDDropdown(data)
{
    var $el = $("#payer_firm_id"),
        selectedVal = $el.val();
    $el.empty(); // remove old options
    $el.append($("<option></option>")
        .attr("value", "").text(""));
    $.each(data.options, function(value,key) {
        var optionEl = $("<option></option>")
            .attr("value", value).text(key);
        if(value == selectedVal)
        {
            optionEl.attr("selected",true);
        }
        $el.append(optionEl);
    });
    if(data.options.length == 0){
        $.post(_URL+"/admin/documents/remove-payer-firm-id",{document_id:data.document_id},function()
        {

        });
    }
}